
// Libraries
import * as React from 'react'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import DuoSection from '../../components/duoSection'

class BallotsPayPage extends React.Component {
	render() {
		return <Layout className="ballots-successful nav-blue-half no-footer">
			<Seo title="Payment complete" />
			<DuoSection BallotsPay>
				<div>
					<div className="content-wrap">
						<h1>Payment complete</h1>
						<p>Thank you for your payment.</p>
						<p>No further action is needed for now, but keep an eye on your email. Concerts for Carers will be in touch soon to organise you receiving your tickets.</p>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}
}

export default BallotsPayPage
